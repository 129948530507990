import axios from 'axios'

export function login(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/auth/login', formData).then(res => {
            if (res.data.ret == 0) resolve(res.data);
            else reject(res.data.ret);
        })
    })
}

export function loginByCode(formData) {
    return new Promise((resolve, reject) => {
        axios.post('/auth/loginByCode', formData).then(res => {
            if (res.data.ret == 0) resolve(res.data);
            else reject(res.data.ret);
        })
    })
}